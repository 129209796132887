import Ellipse30 from '@/assets/messageDemo/Ellipse30.jpg';
import Ellipse28 from '@/assets/messageDemo/Ellipse28.jpg';
import Ellipse26 from '@/assets/messageDemo/Ellipse26.jpg';
import Ellipse12 from '@/assets/messageDemo/Ellipse12.jpg';
import Ellipse22 from '@/assets/messageDemo/Ellipse22.jpg';
import Ellipse17 from '@/assets/messageDemo/Ellipse17.jpg';
import Ellipse14 from '@/assets/messageDemo/Ellipse14.jpg';
import Ellipse20 from '@/assets/messageDemo/Ellipse20.jpg';
import Ellipse24 from '@/assets/messageDemo/Ellipse24.jpg';
import message1 from '@/assets/messageDemo/message1.jpg';
import message2 from '@/assets/messageDemo/message2.jpg';
import message3 from '@/assets/messageDemo/message3.jpg';
import message1Video from '@/assets/messageDemo/message1.mp4';

export const jsonData = {
  data: {
    total: 8,
    length: 8,
    chat_sessions: [
      {
        title: 'Alex',
        avatar_url: Ellipse30,
        chat_type: 1,
        last_message: '[Picture]',
        last_time: 1694695684,
        chat_session_id:
          '2c624232cdd221771294dfbb310aca000a0df6ac8b66b696d90ef06fdefb64a3',
        unique_id:
          'demo-device-dddd00022c624232cdd221771294dfbb310aca000a0df6ac8b66b696d90ef06fdefb64a3',
      },
      {
        title: 'Lily',
        avatar_url: Ellipse28,
        chat_type: 1,
        last_message: 'Sounds like a plan. See you there.',
        last_time: 1694632694,
        chat_session_id:
          '7902699be42c8a8e46fbbb4501726517e86b22c56a189f7625a6da49081b2451',
        unique_id:
          'demo-device-dddd00027902699be42c8a8e46fbbb4501726517e86b22c56a189f7625a6da49081b2451',
      },
      {
        title: 'Ben',
        avatar_url: Ellipse26,
        chat_type: 1,
        last_message: "Nice, can't wait to watch it.",
        last_time: 1694529190,
        chat_session_id:
          'e7f6c011776e8db7cd330b54174fd76f7d0216b612387a5ffcfb81e6f0919683',
        unique_id:
          'demo-device-dddd0002e7f6c011776e8db7cd330b54174fd76f7d0216b612387a5ffcfb81e6f0919683',
      },
      {
        title: 'Emily',
        avatar_url: Ellipse12,
        chat_type: 1,
        last_message: "Thanks, let's nail this presentation!",
        last_time: 1694456534,
        chat_session_id:
          'ef2d127de37b942baad06145e54b0c619a1f22327b2ebbcfbec78f5564afe39d',
        unique_id:
          'demo-device-dddd0002ef2d127de37b942baad06145e54b0c619a1f22327b2ebbcfbec78f5564afe39d',
      },
      {
        title: 'Lucas',
        avatar_url: Ellipse22,
        chat_type: 1,
        last_message: '[Picture]',
        last_time: 1694351260,
        chat_session_id:
          '4b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a',
        unique_id:
          'demo-device-dddd00024b227777d4dd1fc61c6f884f48641d02b4d121d3fd328cb08b5531fcacdabf8a',
      },
      {
        title: 'Olivia',
        avatar_url: Ellipse17,
        chat_type: 1,
        last_message: '[Video]',
        last_time: 1694298765,
        chat_session_id:
          '4e07408562bedb8b60ce05c1decfe3ad16b72230967de01f640b7e4729b49fce',
        unique_id:
          'demo-device-dddd00024e07408562bedb8b60ce05c1decfe3ad16b72230967de01f640b7e4729b49fce',
      },
      {
        title: 'Ethan',
        avatar_url: Ellipse14,
        chat_type: 1,
        last_message: 'Perfect, see you there!',
        last_time: 1694189885,
        chat_session_id:
          'd4735e3a265e16eee03f59718b9b5d03019c07d8b6c51f90da3a666eec13ab35',
        unique_id:
          'demo-device-dddd0002d4735e3a265e16eee03f59718b9b5d03019c07d8b6c51f90da3a666eec13ab35',
      },
      {
        title: 'Liam',
        avatar_url: Ellipse20,
        chat_type: 1,
        last_message: '[Picture]',
        last_time: 1694119759,
        chat_session_id:
          '6b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b',
        unique_id:
          'demo-device-dddd00026b86b273ff34fce19d6b804eff5a3f5747ada4eaa22f1d49c01e52ddb7875b4b',
      },
    ],
    update_time: 1699005148,
  },
};

export const jsonData1 = {
  data1: {
    total: 5,
    messages: [
      {
        avatar_url: Ellipse30,
        send_side: 2,
        sender: 'Alex',
        type: 2,
        attach_content: '',
        date: 1694781995,
        oss_key: 'demo/images/demo-message-image-1325478.jpg',
        file_name: 'demo-message-image-1325478.jpg',
        file_url: message1,
      },
      {
        avatar_url: Ellipse30,
        send_side: 2,
        sender: 'Alex',
        type: 1,
        content: "Sounds good. I'll see you there!",
        attach_content: '',
        date: 1694695684,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Sure, morning works for me. Let's meet at 7 AM.",
        attach_content: '',
        date: 1694695639,
      },
      {
        avatar_url: Ellipse30,
        send_side: 2,
        sender: 'Alex',
        type: 1,
        content:
          'Sorry Mia, I have soccer practice. How about tomorrow morning?',
        attach_content: '',
        date: 1694695595,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: 'Hey Alex, want to go for a jog in the park this evening?',
        attach_content: '',
        date: 1694695510,
      },
    ],
  },
  data2: {
    total: 4,
    messages: [
      {
        avatar_url: Ellipse28,
        send_side: 2,
        sender: 'Lily',
        type: 1,
        content: 'Sounds like a plan. See you there.',
        attach_content: '',
        date: 1694632694,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content:
          "I know, let's meet at the library tomorrow to review together.",
        attach_content: '',
        date: 1694632650,
      },
      {
        avatar_url: Ellipse28,
        send_side: 2,
        sender: 'Lily',
        type: 1,
        content: 'Yeah, been going through the chapters. This is tough!',
        attach_content: '',
        date: 1694632604,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: 'Lily, have you started studying for the chemistry test?',
        attach_content: '',
        date: 1694632519,
      },
    ],
  },
  data3: {
    total: 4,
    messages: [
      {
        avatar_url: Ellipse26,
        send_side: 2,
        sender: 'Ben',
        type: 1,
        content: "Nice, can't wait to watch it.",
        attach_content: '',
        date: 1694529190,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Yeah, saw it last night. It's worth the hype!",
        attach_content: '',
        date: 1694529144,
      },
      {
        avatar_url: Ellipse26,
        send_side: 2,
        sender: 'Ben',
        type: 1,
        content: 'Not yet, planning to see it this weekend. Have you seen it?',
        attach_content: '',
        date: 1694529099,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content:
          "Hey Ben, did you watch that new movie everyone's talking about?",
        attach_content: '',
        date: 1694529014,
      },
    ],
  },
  data4: {
    total: 3,
    messages: [
      {
        avatar_url: Ellipse12,
        send_side: 2,
        sender: 'Emily',
        type: 1,
        content: "Thanks, let's nail this presentation!",
        attach_content: '',
        date: 1694456534,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Oh, totally forgot. I'll email it to you in a bit.",
        attach_content: '',
        date: 1694456490,
      },
      {
        avatar_url: Ellipse12,
        send_side: 2,
        sender: 'Emily',
        type: 1,
        content: 'Mia, remember the group project? Can you send me your part?',
        attach_content: '',
        date: 1694456405,
      },
    ],
  },
  data5: {
    total: 4,
    messages: [
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 2,
        attach_content: '',
        date: 1694437590,
        oss_key: 'demo/images/demo-message-image-966342.jpg',
        file_name: 'demo-message-image-966342.jpg',
        file_url: message3,
      },
      {
        avatar_url: Ellipse22,
        send_side: 2,
        sender: 'Lucas',
        type: 1,
        content: "Thanks, you're a legend!",
        attach_content: '',
        date: 1694351260,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Of course, I'll share them with you.",
        attach_content: '',
        date: 1694351190,
      },
      {
        avatar_url: Ellipse22,
        send_side: 2,
        sender: 'Lucas',
        type: 1,
        content: "Mia, can you send me the notes from yesterday's lecture?",
        attach_content: '',
        date: 1694351114,
      },
    ],
  },
  data6: {
    total: 5,
    messages: [
      {
        avatar_url: Ellipse17,
        send_side: 2,
        sender: 'Olivia',
        type: 3,
        attach_content: '',
        date: 1694385049,
        oss_key: 'demo/videos/native-message-video-7691942.mp4',
        file_name: 'native-message-video-7691942.mp4',
        file_url: message1Video,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Not yet, it's on my to-do list for the weekend.",
        attach_content: '',
        date: 1694298765,
      },
      {
        avatar_url: Ellipse17,
        send_side: 2,
        sender: 'Olivia',
        type: 1,
        content:
          'I know, right? By the way, have you started reading the new novel for English class?',
        attach_content: '',
        date: 1694298730,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Haha, that's hilarious! Thanks for sharing.",
        attach_content: '',
        date: 1694298684,
      },
      {
        avatar_url: Ellipse17,
        send_side: 2,
        sender: 'Olivia',
        type: 1,
        content: 'Mia, check out this funny cat video I found!',
        attach_content: '',
        date: 1694298649,
      },
    ],
  },
  data7: {
    total: 4,
    messages: [
      {
        avatar_url: Ellipse14,
        send_side: 2,
        sender: 'Ethan',
        type: 1,
        content: 'Perfect, see you there!',
        attach_content: '',
        date: 1694189885,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: 'How about 1 PM?',
        attach_content: '',
        date: 1694189839,
      },
      {
        avatar_url: Ellipse14,
        send_side: 2,
        sender: 'Ethan',
        type: 1,
        content: 'Sounds good, what time?',
        attach_content: '',
        date: 1694189804,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content:
          'Hey Ethan, wanna grab lunch at the new burger joint tomorrow?',
        attach_content: '',
        date: 1694189709,
      },
    ],
  },
  data8: {
    total: 6,
    messages: [
      {
        avatar_url: Ellipse20,
        send_side: 2,
        sender: 'Liam',
        type: 2,
        attach_content: '',
        date: 1694206135,
        oss_key: 'demo/images/demo-message-image-4687325.jpg',
        file_name: 'demo-message-image-4687325.jpg',
        file_url: message2,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "Thanks, you're a lifesaver!",
        attach_content: '',
        date: 1694119759,
      },
      {
        avatar_url: Ellipse20,
        send_side: 2,
        sender: 'Liam',
        type: 1,
        content: "Sure, I'll send you a photo of my solution.",
        attach_content: '',
        date: 1694119735,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: "I'm stuck on question 5. Can you help me?",
        attach_content: '',
        date: 1694119659,
      },
      {
        avatar_url: Ellipse20,
        send_side: 2,
        sender: 'Liam',
        type: 1,
        content: 'Yeah, just completed it. How about you?',
        attach_content: '',
        date: 1694119594,
      },
      {
        avatar_url: Ellipse24,
        send_side: 1,
        sender: 'Mia',
        type: 1,
        content: 'Hey Liam, did you finish the math homework?',
        attach_content: '',
        date: 1694119519,
      },
    ],
  },
};
